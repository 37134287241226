import { TENANT_VILMERS } from '../constants/featureFlags'
import { getComponentGroupCode } from '../utilityFunctions/getComponentGroupName'
import { getProfileFromLanguage } from '../utilityFunctions/getProfile'
import {
  ArmrestsPosition,
  getArmrestsPosition,
} from '../utilityFunctions/sofaShapeUtils'

const getProductName = (product) => {
  return product?.advanced_product?.advanced_product_profile.name.split(' ')[0]
}

export const SITTING_CUSHION_DESIGN_CODE = 'sitting-cushion-design'
export const BACK_CUSHION_DESIGN_CODE = 'back-cushion-design'
export const SITTING_CUSHION_COMFORT_CODE = 'sitting-cushion-comfort'
export const BACK_CUSHION_COMFORT_CODE = 'back-cushion-comfort'
export const ARMREST_CODE = 'armrest'
export const LEGS_CODE = 'legs'
export const THREADS_CODE = 'threads-type'
export const THREADS_COLOR_CODE = 'threads-colors'
export const LOGOS_CODE = 'logos'
export const SHOOTING_CODE = 'shooting'
export const DIRECTION_CODE = 'direction'
export const MODEL_CODE = 'model'
export const MATTRESS_COVER = 'product-mattress-cover'
export const MATTRESS = 'product-mattress'
export const TOP_MATTRESS = 'product-top-mattress'

const getSingleComponentConfigString = (
  product,
  fabrics,
  additionalComponents,
  fabricCombination,
  setId,
  itemId,
  item,
  containsLogo = false,
) => {
  //console.log('getSingleComponentConfigString', item)
  if (!item) {
    return {}
  }
  const productName = getProductName(product)

  const withGroupNames = additionalComponents.map((component) => ({
    ...component,
    groupCode: getComponentGroupCode(
      product.advanced_product.additional_component_groups,
      component.additionalComponentGroupId,
    ),
  }))

  // find group with name porankiai
  let armRestLeft = ''
  let armRestRight = ''

  const armrests = additionalComponents?.find(
    (c) => c.groupCode === ARMREST_CODE + '-' + item.name,
  )

  let armrestConfig = ''
  if (armrests) {
    armRestLeft =
      getArmrestsPosition(item.type) === ArmrestsPosition.LEFT ||
      getArmrestsPosition(item.type) === ArmrestsPosition.BOTH
        ? armrests.code.split(':')[1] + 'X1' // replace last symbol with 1 (left armrest)
        : ''

    armRestRight =
      getArmrestsPosition(item.type) === ArmrestsPosition.RIGHT ||
      getArmrestsPosition(item.type) === ArmrestsPosition.BOTH
        ? armrests.code.split(':')[1] + 'X2' // replace last symbol with 2 (right armrest)
        : ''

    armrestConfig = `${armRestLeft}${armRestRight}`
  }

  // baldam turintiems abu porankius (baigiasi penketu) reikia paduoti abu defaultinius porankiu id
  if (armrestConfig.length === 0) {
    if (item.code?.[item.code?.length - 1] === '5') {
      armrestConfig = 'AXXXXXAXXXXX'
    } else {
      armrestConfig = 'AXXXXX'
    }
  }

  // get armrest design from metadata
  let frameDesign = 'XX'
  try {
    frameDesign = JSON.parse(armrests?.metadata ?? '{}')?.frame_design ?? 'XX'
  } catch (e) {
    console.error('Error parsing armrest metadata', e)
  }

  const getSelectedComponent = (groupCode: string) =>
    withGroupNames.find((group) => group?.groupCode === groupCode)

  const getSelectedComponentMulti = (groupCode: string) =>
    withGroupNames.filter((group) => group?.groupCode?.startsWith(groupCode))

  const getSelectedCode = (groupCode: string) =>
    getSelectedComponent(groupCode)?.code

  // --- SHOOTING ---
  let shooting = getSelectedCode(SHOOTING_CODE)
  if (shooting !== '0') {
    const itemCategery = item.code?.split('-')[0]
    if (
      (itemCategery === '51' && item.code.endsWith('X5')) ||
      itemCategery === '53' ||
      itemCategery === '54' ||
      itemCategery === '56'
    ) {
      shooting = '1'
    } else {
      shooting = '2'
    }
  }

  // Hnadling cases when module has only one shooting code, like 51-NEWPORT-B095X3, that has only one shooting code: 2
  if (item.metadata?.codes_shooting) {
    if (item.metadata.codes_shooting.length === 1) {
      shooting = item.metadata.codes_shooting[0]
    }
  }

  // --- SHOOTING ---
  let npComfort = getSelectedCode(BACK_CUSHION_COMFORT_CODE)
  const moduleNPComfort = item.metadata?.['codes_' + BACK_CUSHION_COMFORT_CODE]
  if (!npComfort && moduleNPComfort) {
    npComfort = moduleNPComfort[0]
  }

  //NP COMFORT

  // --- LEGS ---
  let leg = getSelectedCode(LEGS_CODE) ?? '000'
  // if module code ends with 0, then it is a middle frame (corner, seater, etc.)
  const isComponentWithoutArmerst =
    item.code?.endsWith('0') ||
    item.code?.endsWith('1') ||
    item.code?.endsWith('2')

  if (isComponentWithoutArmerst && item.metadata?.additionalLegs?.length > 0) {
    let legComponent = getSelectedComponent(LEGS_CODE)
    // find conponents name
    const legName = getProfileFromLanguage(
      legComponent?.additional_component_profiles,
      'lt',
    )?.name
    let additionalLeg

    if (legName) {
      const nameParts = legName.split(' ')
      const legHeight = nameParts[0]
      const legNumber = nameParts[1]

      // cut off two first elements and join the rest
      const legColor = nameParts.slice(2).join(' ')

      //filter out module legs that are same height and color
      const matchingColorAndHeighLegs = item.metadata.additionalLegs?.filter(
        (l) => l.legHeight === legHeight && l.legColor === legColor,
      )

      // looking for leg with same leg number
      const legWithSameLegNumber = matchingColorAndHeighLegs.find(
        (l) => l.legNumber === legNumber,
      )
      if (legWithSameLegNumber) {
        additionalLeg = legWithSameLegNumber.code
      }

      if (!additionalLeg && matchingColorAndHeighLegs.length > 0) {
        additionalLeg = matchingColorAndHeighLegs[0].code
      }

      // if (matchingColorAndHeighLegs.length == 1) {
      //   additionalLeg = matchingColorAndHeighLegs[0].code
      // } else {
      //   // look for leg that has same legNumber as metadata
      //   const legWithCode = matchingColorAndHeighLegs.find((l) => l.legNumber === legNumber)
      //   console.log('legWithCode', legWithCode)
      //   if (legWithCode) {
      //     additionalLeg = legWithCode.code
      //   }
      // }

      // Case when module shoud not have any legs or there some default value, then use that default value
      // This also covers case for mudules like B075X0 which shoudl get 000 as a leg code.
      if (!additionalLeg && item.metadata.additionalLegs.length === 1) {
        const defaultLeg = item.metadata.additionalLegs[0]
        additionalLeg = defaultLeg.code
      }

      // console.log('data', {module: item.code, legHeight, legNumber, legColor, filteredLegs, leg, additionalLeg, moduleLegs: item.metadata.legs})

      leg = additionalLeg ?? leg
    }
  }

  // get logo name
  let logoComponent = getSelectedComponent(LOGOS_CODE)
  const logoName = getProfileFromLanguage(
    logoComponent?.additional_component_profiles,
    'lt',
  )?.name

  //get thread colors // we need at least two colors. event they are 0|0
  const threads = getSelectedComponentMulti(THREADS_COLOR_CODE)?.map(
    (thread) => thread.code,
  )
  if (threads.length === 0) {
    threads.push('0')
    threads.push('0')
  }
  if (threads.length === 1) {
    threads.push('0')
  }

  const c = {
    setId,
    itemId,
    //category: 51,
    /// these values are hardcoded
    price: 100,
    discount: 0,
    quantity: 1,
    requestedShipDate: '2025-01-01',
    /// ---------------------
    armrestConfig,
    productName,
    moduleCode: item.code,
    moduleName: item.name,
    shapeType: item.type,
    armrestsPosition: getArmrestsPosition(item.type),
    armRestLeft,
    armRestRight,
    leg,
    frameDesign,
    sittingCushionDesign: getSelectedCode(SITTING_CUSHION_DESIGN_CODE),
    backCushionDesign: getSelectedCode(BACK_CUSHION_DESIGN_CODE),
    sittingCushionComfort: getSelectedCode(SITTING_CUSHION_COMFORT_CODE),
    backCushionComfort: npComfort,
    threads: threads,
    threads_color: getSelectedCode(THREADS_COLOR_CODE),
    logo: containsLogo ? getSelectedCode(LOGOS_CODE) ?? '0' : '0',
    logoName,
    shooting,
    fabric: fabrics?.fabricGroupObject?.code,
    //fabrics_raw: fabrics,

    fabricColor: fabrics?.fabricObject?.code,
    direction: getSelectedCode(DIRECTION_CODE),
    //fabricsRaw: fabrics,
    fabrics: Object.values(fabrics.combinationFabrics ?? {})
      .map((f: any) => ({
        position: f.option.code,
        fabric: f.fabricGroupObject.code,
        fabricColor: f.fabricObject.code,
      }))
      .sort((a, b) => a.position.localeCompare(b.position)),

    fabricCombination: fabricCombination?.code,
  }

  //console.log("Config", c)

  let fabricColors = ''
  c.fabrics.map((f, i) => {
    fabricColors += `${f.fabric}-${f.fabricColor}`
    if (i < c.fabrics.length - 1) {
      fabricColors += '|'
    }
  })

  const configuration =
    `${c.armrestConfig}` +
    `.${c.sittingCushionDesign}${c.sittingCushionComfort}.${c.backCushionDesign}${c.backCushionComfort}` +
    `.${c.frameDesign}.${c.shooting}.${
      c.fabricCombination
    }.${fabricColors}.${c.threads.join('|')}` +
    `.${c.logo}.${c.leg}.${c.direction}`
  return { ...c, configuration }
}

const hasLeftArmrest = (item) => {
  return (
    getArmrestsPosition(item.type) === ArmrestsPosition.LEFT ||
    getArmrestsPosition(item.type) === ArmrestsPosition.BOTH
  )
}
const hasRightArmrest = (item) => {
  return (
    getArmrestsPosition(item.type) === ArmrestsPosition.RIGHT ||
    getArmrestsPosition(item.type) === ArmrestsPosition.BOTH
  )
}

export const getConfigString = ({
  product,
  sofaCombinations,
  fabrics,
  additionalComponents,
  fabricCombination,
  advancedProductTotalPricesPerModule,
}) => {
  if (process.env.NEXT_PUBLIC_TENANT !== TENANT_VILMERS) {
    return []
  }

  const sets = []
  // Going through all combinations and creating list (sets) if list (items). Each item is a configuration string
  let setId = 1
  // console.log('getConfigString', product)

  if (product.advanced_product.advanced_product_type === 'SOFA') {
    for (const [i, combination = []] of sofaCombinations.entries() ?? []) {
      const newSet = []
      const mudulesCount = Object.entries(combination).length
      let index = 0
      let logoIsSet = false

      // --- LOGOS ---
      // Logo ant modulio su kairiniu porankiu, jei nėra, tada ant modulio su dešininiu porankiu, jei abiejose pusėse nėra porankio, tada ant kairinio modulio.
      // Taip pat logo ir ant visų set'ų 51gr su X5 (abiem porankiais), 53gr foteliams, 54gr pufams
      // Jeigu klientas užsakytų nepilną kombinaciją/set'ą, reiktų logo dėti tik ant porankio, vadinasi tik ant X3 arba X4 modulio,
      // jei set'as susidarytų tik iš modulių be porankio X0 - logo nededame (būna atvejų kai pasipildo turimą kombinaciją arba baldas visada yra baigtais šonais ir galima sakytis kombinacijas be porankių)
      const shapes = Object.values(combination) as any
      const shapesLogos = (Object.values(combination) as any).map((s) => false)

      if (hasLeftArmrest(shapes[0]?.attrs?.originalSofaForm)) {
        shapesLogos[0] = true
      } else {
        const last = shapes[shapes.length - 1]?.attrs?.originalSofaForm
        if (hasRightArmrest(last)) {
          shapesLogos[shapesLogos.length - 1] = true
        } else {
          // find if module has left connector and check if its connected to another module
          const leftc = shapes[0]?.attrs?.connectors?.find(
            (c) => c.type === 'left',
          )
          if (leftc && !shapes[0]?.attrs.connected?.left) {
            shapesLogos[0] = false
          } else {
            shapesLogos[0] = true
          }
        }
      }

      for (const [j, shape] of Object.entries(combination)) {
        const itemId = parseInt(j) + 1
        const item = (shape as any)?.attrs?.originalSofaForm

        const modulePrice = advancedProductTotalPricesPerModule?.find(
          (p) => p.shape === item.code,
        )?.price

        const config = getSingleComponentConfigString(
          product,
          fabrics,
          additionalComponents,
          fabricCombination,
          setId,
          itemId,
          item,
          shapesLogos[j],
        )

        newSet.push({ ...config, price: modulePrice })
      }
      sets.push(newSet)
    }
  } else if (
    product.advanced_product.advanced_product_type === 'OTHER_WITH_FABRICS'
  ) {
    const newSet = []

    // find model component
    const modelComponent = additionalComponents.find(
      (component) => component.groupCode === MODEL_CODE,
    )
    // console.log('modelComponent', modelComponent)
    const config = getSingleComponentConfigString(
      product,
      fabrics,
      additionalComponents,
      fabricCombination,
      setId,
      1,
      modelComponent,
      true,
    )
    newSet.push({ ...config, price: 9.9 })

    // MATTRESS COVER ========================
    //Handling beds case when there are mattress cover
    const mattressCover = additionalComponents.find(
      (component) => component.groupCode === MATTRESS_COVER,
    )
    if (mattressCover && mattressCover.code !== '0') {
      const mattressCoverConfig = getSingleComponentConfigString(
        product,
        fabrics,
        additionalComponents.map((component) => ({
          ...component,
          code: component.groupCode === LEGS_CODE ? '0' : component.code,
        })),
        fabricCombination,
        setId,
        2,
        mattressCover,
        true,
      )
      newSet.push({ ...mattressCoverConfig, price: 9.9 })
    }
    const mattress = additionalComponents.find(
      (component) => component.groupCode === MATTRESS,
    )

    //console.log('additionalComponents', additionalComponents)
    // MATTRESS ========================
    if (mattress) {
      setId += 1
      const mattressConfig = getSingleComponentConfigString(
        product,
        fabrics,
        additionalComponents.map((component) => ({
          ...component,
          code: component.groupCode === LEGS_CODE ? '0' : component.code,
        })),
        fabricCombination,
        setId,
        1,
        mattress,
        false,
      )
      newSet.push({ ...mattressConfig, price: 9.9 })
    }
    const topMattress = additionalComponents.find(
      (component) => component.groupCode === TOP_MATTRESS,
    )

    // TOP MATTRESS ========================
    if (topMattress && topMattress.code !== '0') {
      setId += 1
      const topMattressConfig = getSingleComponentConfigString(
        product,
        fabrics,
        additionalComponents.map((component) => ({
          ...component,
          code: component.groupCode === LEGS_CODE ? '0' : component.code,
        })),
        fabricCombination,
        setId,
        1,
        topMattress,
        false,
      )
      newSet.push({ ...topMattressConfig, price: 9.9 })
    }

    sets.push(newSet)
  }
  //console.log('sets', sets)
  return sets
}
