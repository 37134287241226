import { useReactiveVar } from '@apollo/client'
import { Box, CardMedia, Drawer, Grid, Theme, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'next-i18next'
import React, { useEffect, useState } from 'react'
import {
  selectedAdvancedProductFabricCombinationVar,
  selectedAdvancedProductFabricVar,
} from '../../../../../store/reactiveVars'
import { useAppContext } from '../../../../../context/appContext'
import { getProfileFromLanguage } from '../../../../../utilityFunctions/getProfile'
import FabricsSelectWithDrawer from './FabricsSelectWithDrawer'
import FabricsCombinationSelectWithDrawer from './FabricsCombinationSelectWithDrawer'
import SectionTitle from '../SectionTitle'

export default function FabricsWithCombinationsSelectWrapper({
  fabricGroups,
  fabricCombinations,
}) {
  if (fabricCombinations.length > 0) {
    return (
      <FabricsWithCombinationsSelect
        fabricCombinations={fabricCombinations}
        fabricGroups={fabricGroups}
      />
    )
  } else {
    return (
      <FabricsSelectWithDrawer
        title={'Fabrics'}
        fabricGroups={fabricGroups}
        option={null}
      />
    )
  }
}

export function FabricsWithCombinationsSelect({
  fabricGroups,
  fabricCombinations,
}) {
  const { t } = useTranslation(['common'])
  const chooseFabricText = t('fabric-configuration-text')

  const selectedAdvancedProductFabricCombination = useReactiveVar(
    selectedAdvancedProductFabricCombinationVar,
  )
  const { locale } = useAppContext()

  if (!selectedAdvancedProductFabricCombination.fabricCombination) {
    selectedAdvancedProductFabricCombinationVar({
      fabricCombination: fabricCombinations[0],
    })
  }

  const options =
    selectedAdvancedProductFabricCombination?.fabricCombination
      ?.fabricCombinationOptions

  const selectedAdvancedProductFabric = useReactiveVar(
    selectedAdvancedProductFabricVar,
  )

  useEffect(() => {
    if (!selectedAdvancedProductFabric.combinationFabrics) {
      return
    }

    //  console.log('UPDATING facris select', selectedAdvancedProductFabric)

    // when fabric colors count changes to lower than before, it needs to remove the last ones
    let newCombination = {}

    const values = Object.values(
      selectedAdvancedProductFabric.combinationFabrics,
    )

    const options =
      selectedAdvancedProductFabricCombination?.fabricCombination
        ?.fabricCombinationOptions

    // Maps a new list of fabrics selects, and looks for default value in the option with the same  code, otherwise sets the first one as default
    options.map((option, index) => {
      const selectedValue: any =
        values.find((value: any) => value.option.code === option.code) ??
        values[0]
      //console.log('selectedValue', selectedValue)
      newCombination[option.id] = {
        ...selectedValue,
        option,
      }
      //}
    })

    console.log(
      'newCombination',
      newCombination,
      selectedAdvancedProductFabric.combinationFabrics,
    )

    if (
      JSON.stringify(selectedAdvancedProductFabric.combinationFabrics) !==
      JSON.stringify(newCombination)
    ) {
      console.log('UPDATING facris select', {
        ...selectedAdvancedProductFabric,
        combinationFabrics: newCombination,
      })
      selectedAdvancedProductFabricVar({
        ...selectedAdvancedProductFabric,
        combinationFabrics: newCombination,
      })
    }
  }, [selectedAdvancedProductFabricCombination?.fabricCombination])

  const optionsSorted = [...(options ?? [])].sort(
    (a: { code: string }, b: { code: string }) => a.code?.localeCompare(b.code),
  )

  return (
    // <Grid id="fabricSelectDrawer" item xs={12} lg={12}>
    //   <SectionTitle title={chooseFabricText} />
    <Grid
      container
      item
      xs={12}
      lg={12}
      // style={{ paddingLeft: '24px', paddingRight: '24px' }}
    >
      {fabricCombinations.length > 1 ? (
        <FabricsCombinationSelectWithDrawer
          fabricCombinations={fabricCombinations}
        />
      ) : null}

      {optionsSorted?.map((option, index) => {
        const name = getProfileFromLanguage(
          option.fabricCombinationOptionProfiles,
          locale,
        )?.name
        return (
          <FabricsSelectWithDrawer
            key={index}
            title={name}
            fabricGroups={fabricGroups}
            option={option}
          />
        )
      })}
    </Grid>
    // </Grid>
  )
}
