import { useReactiveVar } from '@apollo/client'
import {
  AppBar,
  CardMedia,
  Container,
  Grid,
  Hidden,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Theme,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import React, { useState } from 'react'
import { cartItemsVar, orderVar } from '../../store/reactiveVars'
import {
  DeliveryAddressForm,
  DeliveryContactForm,
  PaymentForm,
  ProductsSideBox,
  ShippingForm,
} from '../DesktopElements/CheckoutElements'
import { MobileOrderSummary } from '../ResponsiveElements/ResponsiveCheckoutElements'
import { useAppContext } from '../../context/appContext'
import { imageFallback } from '../../constants/Images'
import { TENANT_VILMERS } from '../../constants/featureFlags'
import { OrderItem, OrderItemStatus } from '../../store/models'
//import ConfirmOrderForm from '../DesktopElements/CheckoutElements/ConfirmOrderForm'

const useStyles = makeStyles(
  (theme: Theme) => ({
    logo: {
      height: '3rem',
      backgroundSize: 'contain',
      cursor: 'pointer',
    },
    containerRoot: {
      overflow: 'hidden',
      [theme.breakpoints.down('lg')]: {
        padding: 0,
      },
    },
    appBar: {
      padding: '1rem 0',
      position: 'relative',
      backgroundColor: '#fff',
    },
    gridItem: {
      maxWidth: '100%',
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
    },
    paper: {
      width: 700,
      padding: theme.spacing(4, 5, 4, 4),
      [theme.breakpoints.down('lg')]: {
        width: '100%',
        padding: theme.spacing(2),
      },
    },
    stepper: {
      width: '30%',
      margin: '1rem auto',
      padding: theme.spacing(3, 0, 3),
      backgroundColor: 'transparent',
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
    },
    button: {
      width: '40%',
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
    },
    media: {
      height: '3rem',
      backgroundSize: 'contain',
      cursor: 'pointer',
      alignSelf: 'center',
    },
    previousStepButton: {
      width: 'fit-content',
      cursor: 'pointer',
      display: 'flex',
      color: '#2B2B2B',
      textTransform: 'uppercase',
      textDecoration: 'none',
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down('lg')]: {
        marginBottom: theme.spacing(2),
      },
    },
    productSideBoxContainer: {
      [theme.breakpoints.down('lg')]: {
        display: 'none',
      },
    },
  }),
  { name: 'MuiCheckoutPageComponent' },
)

function getStepContent(
  step,
  handleNext,
  handleNextSubStep,
  cartItems,
  activeCartItemState,
  quickOrder,
) {
  switch (step) {
    case 0:
      return <DeliveryContactForm handleNextSubStep={handleNextSubStep} />
    case 0.5:
      return <DeliveryAddressForm handleNextSubStep={handleNextSubStep} />
    case 1:
      //* This will loop through all the cartItems and if the index is equal to the activeCartItem
      //* (which starts at 0 and is updated every time the user will click the "Next" button)
      //* then we show the ShippingForm component for that product.
      return (
        <>
          {cartItems.map((cartItem, index, self) => {
            if (index === activeCartItemState.activeCartItem) {
              return (
                <ShippingForm
                  key={index}
                  cartItem={cartItem}
                  numberOfItems={self.length}
                  handleNext={handleNext}
                  cartItemPosition={index + 1}
                  isLastCartItem={index + 1 === cartItems.length}
                  activeCartItemState={activeCartItemState}
                />
              )
            }
          })}
        </>
      )
    case 2:
      return (
        <PaymentForm handleNext={handleNext} skipPaymentSelect={quickOrder} />
      )
    default:
      throw new Error(
        "Unknown step in CheckoutPage... This shouldn't happen :(",
      )
  }
}

export default function Checkout() {
  const { t } = useTranslation(['common'])
  const cartSlug = t('cart-slug')
  const stepperOne = t('stepper-one')
  const stepperTwo = t('stepper-two')
  const stepperThree = t('stepper-three')
  const backToBasketCta = t('back-to-basket-cta')
  const backToPreviousStepCta = t('back-to-previous-step-cta')
  const classes = useStyles() as any
  const [activeStep, setActiveStep] = useState(0)
  const [activeCartItem, setActiveCartItem] = useState(0)
  const cartItems = useReactiveVar(cartItemsVar)
  const order = useReactiveVar(orderVar)

  const steps = [stepperOne, stepperTwo, stepperThree]

  //TODO: copy the useEffect in cartPage, to make sure the cartItems are up to date

  const handleNext = () => {
    setActiveStep(activeStep + 1)
  }

  const handleNextSubStep = () => {
    // console.log('Handling SubStep')
    setActiveStep(activeStep + 0.5)
  }

  const skipShippingStep =
    process.env.NEXT_PUBLIC_SKIP_SHIPPING_METHODS === 'true'

  const handleBackSubStep = () => {
    setActiveCartItem(0)
    setActiveStep(activeStep - 0.5)
  }

  const handleBack = () => {
    console.log('handleBack', skipShippingStep, activeStep)
    //* if the step is equal to 2 which means that we are in the payment method step, if the user clicks to go back, we want to reset the activeCartItem
    if (skipShippingStep && activeStep === 2) {
      setActiveStep(0)
      return
    }
    setActiveCartItem(0)
    setActiveStep(activeStep - 1)
  }

  const appContext = useAppContext()
  const shopSettings = appContext ? appContext.shopSettings : null

  const siteLogoSrc = shopSettings
    ? shopSettings.default_manufacturer?.logo_image
      ? shopSettings.default_manufacturer.logo_image.src_xs ??
        shopSettings.default_manufacturer.logo_image.src
      : imageFallback
    : imageFallback

  const createOrderItems = () => {
    const orderItems = cartItems.map((cartItem) => {
      let advancedData = cartItem.advanced_product_data as any
      //-------------------------------------------------

      const modifiedAdditionalComponents =
        advancedData?.selected_additional_components.map(
          (additionalComponent) => {
            return {
              id: additionalComponent.id,
            }
          },
        ) ?? null

      const combinationFabrics =
        advancedData?.selected_fabric?.combinationFabrics
      //----
      let modifiedAdvancedProductData = {
        advanced_product_type: advancedData?.advanced_product_type,
        fabric: advancedData?.selected_fabric?.fabricObject?.id
          ? { id: advancedData.selected_fabric.fabricObject.id }
          : null,
        fabric_code: advancedData?.selected_fabric?.fabricObject?.code ?? null,
        fabric_group: advancedData?.selected_fabric?.fabricGroupObject?.id
          ? {
              id: advancedData.selected_fabric.fabricGroupObject.id,
            }
          : null,
        selected_sofa_combinations:
          advancedData?.selected_sofa_combinations ?? null,

        additional_components: modifiedAdditionalComponents,
        fabricCombination: combinationFabrics
          ? {
              id: advancedData.selected_fabric_combination?.id,
              fabrics: Object.keys(combinationFabrics).map((key) => ({
                fabricId: combinationFabrics[key]?.fabricObject?.id,
                fabric_groupId: combinationFabrics[key]?.fabricGroupObject?.id,
                optionId: Number(key),
              })),
            }
          : null,
      }

      const order_item: OrderItem = {
        id: cartItem.id,
        // price: cartItem.discountedPrice ?? cartItem.price,
        price: cartItem.discountedPrice ?? cartItem.price,
        shipping_price: 0,
        quantity: cartItem.quantity,
        expected_delivery_date: null,
        preferred_delivery_date: null,
        shipping_method: null,
        status: OrderItemStatus.AWAITING_PAYMENT,
        sku: cartItem.sku,
        product_container: {
          id: cartItem.product_container_id,
        },

        //--- Addding advanced product items
        product_type: cartItem.product_type,
        advanced_product_data: modifiedAdvancedProductData,
        integration_configuration: cartItem.integration_configuration,
      }
      return order_item
    })

    orderVar({
      ...order,
      order_items: orderItems,
      total_shipping_price: 0,
    })
  }

  if (activeStep == 0 && !order.order_items?.length) {
    createOrderItems()
  }

  return (
    <>
      <AppBar className={classes.appBar}>
        <Link prefetch={false} href="/">
          <CardMedia image={siteLogoSrc} className={classes.logo} />
        </Link>
      </AppBar>
      <Container
        maxWidth="lg"
        classes={{
          root: classes.containerRoot,
        }}
      >
        <Stepper
          activeStep={Math.floor(activeStep)}
          className={classes.stepper}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {/* BACK NAVIGATION BUTTON */}
        {activeStep !== 0 && activeStep !== steps.length ? (
          <div
            className={classes.previousStepButton}
            onClick={activeStep === 2 ? handleBack : handleBackSubStep}
          >
            <NavigateBeforeIcon />
            <Typography variant="body1">{backToPreviousStepCta}</Typography>
          </div>
        ) : (
          <Link prefetch={false} href={`/${cartSlug}`}>
            <div className={classes.previousStepButton}>
              <NavigateBeforeIcon />
              <Typography variant="body1">{backToBasketCta}</Typography>
            </div>
          </Link>
        )}

        <Grid container spacing={3} justifyContent="center">
          <Grid item className={classes.gridItem}>
            <Paper elevation={0} className={classes.paper}>
              <>
                {/* STEP CONTENT FUNCTION */}
                {getStepContent(
                  activeStep,
                  handleNext,
                  handleNextSubStep,
                  cartItems,
                  { activeCartItem, setActiveCartItem },
                  skipShippingStep,
                )}

                {/* MOBILE ORDER SUMMARY ACCORDION */}
                <Hidden lgUp>
                  <MobileOrderSummary />
                </Hidden>
              </>
            </Paper>
          </Grid>
          {activeStep !== steps.length && (
            <Grid item className={classes.productSideBoxContainer}>
              <ProductsSideBox />
            </Grid>
          )}
        </Grid>
        {/* <InfoBoxes /> */}
      </Container>
    </>
  )
}
