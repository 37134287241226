import { Box, CardMedia, Grid, Theme, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { Media } from '../../../../../lib/artsyFresnel/Media'
import { useAppContext } from '../../../../context/appContext'
import FabricsSelect from './FabricsSelect'
import StickyPricePreview from './StickyPricePreview'
import { useInView } from 'react-intersection-observer'
import AdditionalComponentGroup from './AditionalComponentGroup/AdditionalComponentGroup'
import {
  advancedProductTotalPricesPerModuleVar,
  advancedProductTotalPriceVar,
  advancedProductVarsResetedVar,
  selectedAdditionalComponentsVar,
  selectedAdvancedProductFabricCombinationVar,
  selectedAdvancedProductFabricVar,
} from '../../../../store/reactiveVars'
import AdvancedProductConfigurationPreview from './AdvancedProductConfigurationPreview'
import { useReactiveVar } from '@apollo/client'
import AdditionalComponentGroupWrapper from './AditionalComponentGroup/AdditionalComponentGroupWrapper'
import FabricsWithCombinationsSelect from './AditionalComponentGroup/FabricsWithCombinationsSelect'
import SectionTitle from './SectionTitle'
import ConfigurationBlock, {
  ConfigurationDetails,
} from './Components/ConfigurationBlock'
import { getProfileFromLanguage } from '../../../../utilityFunctions/getProfile'
import { getConfigString } from '../../../../integrationUtils/vilmers'
import { getAdvancedProductPrice } from '../../../../utilityFunctions/priceUtils'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      card: {
        position: 'relative',
      },
      cardContent: {},
    }),
  { name: 'MuiSizeDrawerCardComponent' },
)

export default function AdvancedProductWithFabricsAndAdditionalComponentsV2({
  advancedProduct,
  productPermalink,
  showComponents,
  showConfiguration,
  showFabrics,
  showAddToCartButton,
}) {
  const classes = useStyles() as any
  const { locale } = useRouter()

  const { t, i18n } = useTranslation(['common'])
  const chooseFabricText = t('choose-fabric-text')
  const reviewYourConfigurationText = t('review-your-configuration-text')
  const chooseAdditionalComponentsText = t('choose-additional-components-text')

  const { priceListId } = useAppContext()

  // ==== We now need to map all the shapes to extract available fabrics ===

  const selectedAdditionalComponents = useReactiveVar(
    selectedAdditionalComponentsVar,
  )

  const selectedAdvancedProductFabricCombination = useReactiveVar(
    selectedAdvancedProductFabricCombinationVar,
  )

  const selectedAdvancedProductFabric = useReactiveVar(
    selectedAdvancedProductFabricVar,
  )

  const advancedProductTotalPricesPerModule = useReactiveVar(
    advancedProductTotalPricesPerModuleVar,
  )
  const advancedProductVarsReseted = useReactiveVar(
    advancedProductVarsResetedVar,
  )

  let totalPrice = getAdvancedProductPrice(
    advancedProduct,
    selectedAdvancedProductFabric,
    selectedAdditionalComponents,
    priceListId,
  )

  useEffect(() => {
    advancedProductTotalPriceVar(totalPrice)
  }, [totalPrice, advancedProductVarsReseted])

  // const modelImage = selectedAdditionalComponents.find(
  //   (component) => componencd furt.groupCode === 'model',
  // )?.image

  let availablefabricGroups = [] as any
  for (const priceCategory of advancedProduct?.manufacturer
    ?.fabric_price_category ?? []) {
    for (const group of priceCategory.fabric_groups) {
      // Check if not already addded...
      if (availablefabricGroups.filter((e) => e.id === group.id).length > 0) {
        /* vendors contains the element we're looking for */
      } else {
        // === We determine the groups price here...
        const groupNumber = priceCategory.group_number ?? 0 // CAT 1 / CAT 2 etc...

        let priceMultiplier = 1
        // How many EUR ($) sings do we show?
        if (groupNumber > 2 && groupNumber < 5) {
          priceMultiplier = 2
        } else if (groupNumber > 4 && groupNumber < 7) {
          priceMultiplier = 3
        } else if (groupNumber > 6 && groupNumber < 10) {
          priceMultiplier = 4
        } else if (groupNumber > 9) {
          priceMultiplier = 5
        }

        const object = {
          priceMultiplier,
          form_price_fabric_category: priceCategory,
          ...group,
        }
        availablefabricGroups.push(object)
      }
    }
  }

  // We arange them here by Category number -> CAT 1 first, then the rest...
  const availablefabricGroupsSorted = availablefabricGroups.sort((a, b) =>
    // @ts-ignore
    a.form_price_fabric_category.group_number >
    // @ts-ignore
    b.form_price_fabric_category.group_number
      ? 1
      : -1,
  )
  const discount = advancedProduct?.discount ?? null

  // Additional components data FACTORING =====================
  let additionalComponentGroups =
    advancedProduct.advanced_product.additional_component_groups

  // console.log('fabricGroups :>> ', availablefabricGroups)
  // console.log('advancedProduct inside Fabrics select :>> ', advancedProduct)

  // ===== HANDLEWHIC BLOCK IS IN VIEW

  const [viewingStep, setViewingStep] = useState(0)
  const [finalStep, setFinalStep] = useState(false)

  const [refFabric, inViewFabric, entryFabric] = useInView({
    threshold: 0.4,
  })
  const [refSofaShape, inViewSofaShape, entrySofaShape] = useInView({
    threshold: 0.4,
  })

  const [
    refAdditionalComponents,
    inViewAdditionalComponents,
    entryAdditionalComponents,
  ] = useInView({
    threshold: 0.4,
  })

  const [refPreview, inViewPreview, entryPreview] = useInView({
    threshold: 0.4,
  })

  useEffect(() => {
    let number = 0
    if (inViewFabric) {
      number = 1
    }
    if (inViewSofaShape) {
      number = 2
    }
    if (inViewAdditionalComponents) {
      number = 2
    }
    if (inViewPreview) {
      number = 3
    }
    setViewingStep(number)

    if (number === 3) {
      setFinalStep(true)
    } else {
      setFinalStep(false)
    }
  }, [inViewFabric, inViewSofaShape, inViewPreview, inViewAdditionalComponents])

  const steps = [
    chooseFabricText,
    chooseAdditionalComponentsText,
    reviewYourConfigurationText,
  ]

  //=== We need to select the first item of each group
  useEffect(() => {
    // console.log('========== Selecting component groups')
    if (additionalComponentGroups) {
      let selectedComponents = [] as any
      for (const group of additionalComponentGroups) {
        //--
        let firstComponent = group.additional_components[0]
        if (firstComponent) {
          selectedComponents.push(firstComponent)
        }
      }
      selectedAdditionalComponentsVar(selectedComponents)
    }
  }, [advancedProductVarsReseted])

  if (
    advancedProduct?.advanced_product?.advanced_product_type ===
      'CHAIR_ARMCHAIR' ||
    advancedProduct?.advanced_product?.advanced_product_type === 'BED' ||
    advancedProduct?.advanced_product?.advanced_product_type ===
      'OTHER_WITH_FABRICS'
  ) {
    const fabricCombinations =
      advancedProduct?.advanced_product.fabricCombinations

    const modelComponent = selectedAdditionalComponents?.find(
      (component) => component.groupCode === 'model',
    )
    const modelImage = modelComponent?.image?.src_md

    const modelComponentsGroup = additionalComponentGroups?.find(
      (group) => group.code === 'model',
    )

    const componentProfile = getProfileFromLanguage(
      modelComponent?.additional_component_profiles,
      locale,
    )?.name

    let configs = getConfigString({
      product: advancedProduct,
      sofaCombinations: null,
      fabrics: selectedAdvancedProductFabric,
      additionalComponents: selectedAdditionalComponents,
      fabricCombination:
        selectedAdvancedProductFabricCombination.fabricCombination,
      advancedProductTotalPricesPerModule,
    })

    const configstrings = []
    configs?.forEach((element) => {
      element?.forEach((element2) => {
        configstrings.push({
          configString: element2.configuration,
          module: element2.moduleCode,
          setId: element2.setId,
          itemId: element2.itemId,
        })
      })
    })
    const sofaConfigurationText = t('sofa-configuration-text')

    return (
      <>
        <Grid container id={'configurator-header'}>
          {/* <SectionTitle title={sofaConfigurationText} /> */}
          <SectionTitle title={sofaConfigurationText} />
          <Grid item xs={12} md={8}>
            {selectedAdvancedProductFabricCombination.fabricCombination?.image
              ?.src_md && (
              <Box
                sx={{
                  height: 300,
                  marginBottom: 2,
                  marginRight: 2,
                  backgroundColor: 'white',
                }}
              >
                <CardMedia
                  component="img"
                  sx={{
                    height: '100%',
                    objectFit: 'contain',
                    width: '100%',
                  }}
                  image={
                    selectedAdvancedProductFabricCombination.fabricCombination
                      ?.image?.src_md
                  }
                />
              </Box>
            )}

            <Box
              sx={{
                height: 'auto',
                marginBottom: 2,
                marginRight: 2,
                backgroundColor: 'white',
              }}
            >
              <ConfigurationDetails
                discount={discount}
                advancedProduct={advancedProduct}
                mainImage={modelImage}
                modelName={componentProfile}
                showPrice={true}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            {modelComponentsGroup && (
              <AdditionalComponentGroupWrapper
                key={'model'}
                componentGroup={modelComponentsGroup}
                locale={locale}
              />
            )}

            <FabricsWithCombinationsSelect
              //ref={refFabric}
              fabricCombinations={fabricCombinations}
              fabricGroups={availablefabricGroupsSorted}
            />
            {showComponents &&
              additionalComponentGroups
                ?.filter((group) => group.code !== 'model')
                ?.sort((a, b) => a.order - b.order)
                // filter out threads group if there are multiple fabrics. VILMERS  hack
                ?.map((componentGroup, i) => (
                  <AdditionalComponentGroupWrapper
                    key={i}
                    componentGroup={componentGroup}
                    locale={locale}
                  />
                ))}
          </Grid>
        </Grid>
        <Grid container direction="column" margin={1}>
          {configstrings.map((config, i) => (
            <Typography key={i} variant="caption">
              {config.setId}/{config.itemId}
              <b>{config.module}</b> {config.configString}
            </Typography>
          ))}
        </Grid>
        {showAddToCartButton && (
          <StickyPricePreview
            productPermalink={productPermalink}
            advancedProduct={advancedProduct}
            discount={discount}
            steps={[]}
            viewingStep={viewingStep}
            finalStep={finalStep}
          />
        )}
        {/* <Grid ref={refPreview} item xs={12} lg={12}>
          <AdvancedProductConfigurationPreview
            discount={discount}
            advancedProduct={advancedProduct}
          />
        </Grid> */}
      </>
      // <Media greaterThan="sm">
      // <Grid container justifyContent="space-evenly" spacing={1}>
      //   {showFabrics && (
      //     <>
      //       {fabricCombinations.length > 0 ? (
      //         <FabricsWithCombinationsSelect
      //           //ref={refFabric}
      //           fabricCombinations={fabricCombinations}
      //           fabricGroups={availablefabricGroupsSorted}
      //         />
      //       ) : (
      //         <Grid id="fabricSelect" ref={refFabric} item xs={12} lg={12}>
      //           <FabricsSelect fabricGroups={availablefabricGroupsSorted} />
      //         </Grid>
      //       )}
      //     </>
      //   )}
      //   {modelImage && (
      //     <Box
      //       sx={{
      //         height: 300,
      //         marginBottom: 2,
      //         marginRight: 2,
      //         backgroundColor: 'white',
      //       }}
      //     >
      //       <CardMedia
      //         component="img"
      //         sx={{
      //           height: '100%',
      //           objectFit: 'contain',
      //           width: '100%',
      //         }}
      //         image={modelImage?.src_md}
      //       />
      //     </Box>
      //   )}
      //   <Grid
      //     id="additionalComponentSelect"
      //     ref={refAdditionalComponents}
      //     item
      //     xs={12}
      //     lg={12}
      //   >
      //     {showComponents &&
      //       additionalComponentGroups?.map((componentGroup, i) => (
      //         <AdditionalComponentGroupWrapper
      //           key={i}
      //           componentGroup={componentGroup}
      //           locale={locale}
      //         />
      //       ))}
      //   </Grid>

      // </Grid>
      // </Media>
    )
  } else if (
    advancedProduct?.advanced_product?.advanced_product_type === 'TABLE' ||
    advancedProduct?.advanced_product?.advanced_product_type === 'LIGHTS' ||
    advancedProduct?.advanced_product?.advanced_product_type === 'OTHER'
  ) {
    //====== FABRICS SECTION REMOVED HERE =======

    return (
      <Grid container justifyContent="space-evenly" spacing={1}>
        <Grid
          id="additionalComponentSelect"
          ref={refAdditionalComponents}
          item
          xs={12}
          lg={12}
        >
          {showComponents &&
            additionalComponentGroups?.map((componentGroup, i) => (
              <AdditionalComponentGroupWrapper
                key={i}
                componentGroup={componentGroup}
                locale={locale}
              />
            ))}
        </Grid>

        <Grid ref={refPreview} item xs={12} lg={12}>
          <AdvancedProductConfigurationPreview
            discount={discount}
            advancedProduct={advancedProduct}
          />
        </Grid>
        {showAddToCartButton && (
          <StickyPricePreview
            productPermalink={productPermalink}
            advancedProduct={advancedProduct}
            discount={discount}
            steps={steps}
            viewingStep={viewingStep}
            finalStep={finalStep}
          />
        )}
      </Grid>
    )
  } else {
    return <span></span>
  }
}
