import { CardMedia, Grid, Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { Media } from '../../../../../lib/artsyFresnel/Media'
import { useAppContext } from '../../../../context/appContext'
import FabricsSelect from './FabricsSelect'
import SofaShapeSelect from './SofaShapeSelect'
import dynamic from 'next/dynamic'
import StickyPricePreview from './StickyPricePreview'
import { useInView } from 'react-intersection-observer'
import { Box } from '@mui/material'
import { useReactiveVar } from '@apollo/client'
import {
  advancedProductVarsResetedVar,
  selectedAdditionalComponentsVar,
  selectedAdvancedProductFabricCombinationVar,
  selectedAdvancedProductFabricVar,
  selectedSofaCombinationsVar,
} from '../../../../store/reactiveVars'
import AdditionalComponentGroupWrapper from './AditionalComponentGroup/AdditionalComponentGroupWrapper'

import FabricsWithCombinationsSelect from './AditionalComponentGroup/FabricsWithCombinationsSelect'
import SectionTitle from './SectionTitle'
import PriceListTable from './PriceListTable'
import FabricsSelectWithDrawer from './AditionalComponentGroup/FabricsSelectWithDrawer'

const SofaConfigurationPreview = dynamic(
  () => import('./SofaConfigurationPreview'),
  {
    ssr: false,
  },
)

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      card: {
        position: 'relative',
      },
      cardContent: {},
    }),
  { name: 'MuiSizeDrawerCardComponent' },
)

export default function AdvancedSofaV2({
  advancedProduct,
  productPermalink,
  showSofaShapes,
  showComponents,
  showConfiguration,
  showFabrics,
  showAddToCartButton,
}) {
  const classes = useStyles() as any
  const { locale } = useRouter()

  const { t, i18n } = useTranslation(['common'])
  const productSlug = t('product-slug')
  const chooseFabricText = t('choose-fabric-text')
  const chooseSofaShapeText = t('choose-sofa-shape-text')
  const reviewYourConfigurationText = t('review-your-configuration-text')
  const componentsText = t('components')
  const sofaConfigurationText = t('sofa-configuration-text')
  const [sofaForms, setSofaForms] = useState([])

  const [additionalComponentGroups, setAdditionalComponentGroups] = useState(
    advancedProduct.advanced_product.additional_component_groups,
  )
  const [hasMultipleFabrics, setHasMultipleFabrics] = useState(false)

  // ==== We get shop settings data from APP Context
  const appContext = useAppContext()

  // return (
  //   <Card elevation={0} square={true} className={classes.card}>
  //     <CardContent className={classes.cardContent}>Fabrics...</CardContent>
  //   </Card>
  // )

  // ==== We now need to map all the shapes to extract available fabrics ===
  let availablefabricGroups = [] as any
  for (const priceCategory of advancedProduct?.manufacturer
    ?.fabric_price_category ?? []) {
    for (const group of priceCategory.fabric_groups) {
      // Check if not already addded...
      if (availablefabricGroups.filter((e) => e.id === group.id).length > 0) {
        /* vendors contains the element we're looking for */
      } else {
        // === We determine the groups price here...
        const groupNumber = priceCategory.group_number ?? 0 // CAT 1 / CAT 2 etc...

        let priceMultiplier = 1
        // How many EUR ($) sings do we show?
        if (groupNumber > 2 && groupNumber < 5) {
          priceMultiplier = 2
        } else if (groupNumber > 4 && groupNumber < 7) {
          priceMultiplier = 3
        } else if (groupNumber > 6 && groupNumber < 10) {
          priceMultiplier = 4
        } else if (groupNumber > 9) {
          priceMultiplier = 5
        }

        const object = {
          priceMultiplier,
          form_price_fabric_category: priceCategory,
          ...group,
        }
        availablefabricGroups.push(object)
      }
    }
  }

  // update availablefabricGroups with price categories
  // availablefabricGroups = filterFabricsGroupsWithSofaPrices(
  //   sofaForms,
  //   availablefabricGroups,
  // )

  // We arange them here by Category number -> CAT 1 first, then the rest...
  const availablefabricGroupsSorted = availablefabricGroups.sort((a, b) =>
    // @ts-ignore
    a.form_price_fabric_category.group_number >
    // @ts-ignore
    b.form_price_fabric_category.group_number
      ? 1
      : -1,
  )

  // We need to add the original dimensions so it can be used to sed new dimensions on armrest change
  useEffect(() => {
    const sofaFormsExtended = JSON.parse(
      JSON.stringify(advancedProduct?.advanced_product?.sofa_forms ?? []),
    ).map((form) => ({
      ...form,
      originalDimension: JSON.parse(
        JSON.stringify(form.dimensions ?? { width: 0, height: 0 }),
      ),
    }))

    // console.log('sofaFormsExtended', sofaFormsExtended)

    setSofaForms(sofaFormsExtended)
  }, [advancedProduct?.advanced_product?.sofa_forms])

  const discount = advancedProduct.discount ?? null

  //----
  // We need to follow this, as Var resets happens strangely after render...
  const advancedProductVarsReseted = useReactiveVar(
    advancedProductVarsResetedVar,
  )
  const selectedAdditionalComponents = useReactiveVar(
    selectedAdditionalComponentsVar,
  )

  const selectedSoafForm = useReactiveVar(selectedSofaCombinationsVar)

  const selectedAdvancedProductFabric = useReactiveVar(
    selectedAdvancedProductFabricVar,
  )

  const selectedAdvancedProductFabricCombination = useReactiveVar(
    selectedAdvancedProductFabricCombinationVar,
  )

  useEffect(() => {
    const isContrastStichingSelected = selectedAdditionalComponents.find(
      (component) =>
        component.groupCode === 'threads-type' && component.code === '10',
    )
      ? true
      : false

    const fabrics: any[] = Object.values(
      selectedAdvancedProductFabric?.combinationFabrics ?? {},
    ).sort((a: any, b: any) => a.option.code.localeCompare(b.option.code))

    const fabricsCount = fabrics.length
    if (isContrastStichingSelected) {
      // find how many thread-colors groups are there
      const threadColorGroups = additionalComponentGroups.filter((component) =>
        component.code.startsWith('threads-colors'),
      )

      if (threadColorGroups.length !== fabricsCount) {
        // console.log('Need to add more thread selects')
        // add more thread selects
        const originalAdditionalComponentGroups = [
          ...(additionalComponentGroups ?? []),
        ]

        let modifiedadditionalComponentGroups =
          originalAdditionalComponentGroups.filter(
            (group) => !group.code.startsWith('threads-colors'),
          )
        const threadGroup =
          advancedProduct.advanced_product?.additional_component_groups?.find(
            (component) => component.code.startsWith('threads-colors'),
          )

        for (let i = 0; i < fabricsCount; i++) {
          const newGroupCode = 'threads-colors-' + i
          const nameOveride = 'Siūlai audiniui ' + fabrics[i].option.code
          const newArmrestsGroup = {
            ...threadGroup,
            code: newGroupCode,
            nameOveride: nameOveride,
            order: threadGroup.order + (i + 1) * 0.1,
            additional_components: threadGroup.additional_components.map(
              (component) => ({
                ...component,
                groupCode: newGroupCode,
                groupNameOveride: nameOveride,
              }),
            ),
          }
          modifiedadditionalComponentGroups.push(newArmrestsGroup)
        }

        setAdditionalComponentGroups(modifiedadditionalComponentGroups)

        const selectedComponents = selectedAdditionalComponents.filter(
          (component) =>
            modifiedadditionalComponentGroups.find(
              (group) => group.code === component.groupCode,
            ),
        )
        if (selectedComponents.length !== selectedAdditionalComponents.length) {
          //selectedAdditionalComponentsVar(selectedComponents)
        }
      }
    } else {
      // console.log('Need to remove thread selects')
      // keep only one  thread-color selected compoenent in the list//
      //const selectedComponents = selectedAdditionalComponents.filter(
      // let foundThreadColor = false
      // const selectedComponents = selectedAdditionalComponents.filter(
      //   (component) => {
      //     if (component.groupCode.startsWith('threads-colors')) {
      //       if (foundThreadColor) {
      //         return false
      //       }
      //       foundThreadColor = true
      //     }
      //     return true
      //   },
      // )
      // if (selectedAdditionalComponents.length !== selectedComponents.length) {
      //   selectedAdditionalComponentsVar(selectedComponents)
      // }
    }

    if (!isContrastStichingSelected) {
      let foundThreadColor = false
      const filteredAdditionalComponentGroups =
        additionalComponentGroups?.filter((component) => {
          if (component.code?.startsWith('threads-colors')) {
            if (foundThreadColor) {
              return false
            }
            foundThreadColor = true
          }
          return true
        })
      setAdditionalComponentGroups(filteredAdditionalComponentGroups)
      // console.log(additionalComponentGroups)
      // const selectedComponents = selectedAdditionalComponents.filter(
      //   (component) =>
      //     additionalComponentGroups.find(
      //       (group) => group.code === component.groupCode,
      //     ),
      // )
      // if (selectedComponents.length !== selectedAdditionalComponents.length) {
      //   selectedAdditionalComponentsVar(selectedComponents)
      // }

      //find and remove all thread-colors selected components
      // const selectedComponents = selectedAdditionalComponents.filter(
      //   (component) => !component.groupCode.startsWith('threads-colors-'),
      // )
      // if (selectedAdditionalComponents.length !== selectedComponents.length) {
      //   selectedAdditionalComponentsVar(selectedComponents)
      // }
    }
  }, [
    JSON.stringify(selectedAdditionalComponents),
    JSON.stringify(selectedAdvancedProductFabric),
  ])

  useEffect(() => {
    console.log('Sofa fomr changed')

    let modelWithArmrestInUse = []
    selectedSoafForm.forEach((sofaSet) =>
      sofaSet.forEach((sofaModule) => {
        switch (sofaModule.attrs.armrestPosition) {
          case 'L':
            modelWithArmrestInUse.push({
              name: sofaModule.attrs.originalSofaForm.name,
              code: sofaModule.attrs.originalSofaForm.code,
              armrestPosition: sofaModule.attrs.armrestPosition,
              armrestsConditions:
                sofaModule.attrs.originalSofaForm.metadata?.armrestsConditions,
              sofaModuleId: sofaModule.attrs.id,
            })
            break
          case 'R':
            modelWithArmrestInUse.push({
              name: sofaModule.attrs.originalSofaForm.name,
              code: sofaModule.attrs.originalSofaForm.code,
              armrestPosition: sofaModule.attrs.armrestPosition,
              armrestsConditions:
                sofaModule.attrs.originalSofaForm.metadata?.armrestsConditions,
              sofaModuleId: sofaModule.attrs.id,
            })
            break
          case 'LR':
            modelWithArmrestInUse.push({
              name: sofaModule.attrs.originalSofaForm.name,
              armrestPosition: 'LR',
              code: sofaModule.attrs.originalSofaForm.code,
              armrestsConditions:
                sofaModule.attrs.originalSofaForm.metadata?.armrestsConditions,
              sofaModuleId: sofaModule.attrs.id,
            })
            // code block
            break
          default:
          // code block
        }
      }),
    )
    const originalAdditionalComponentGroups = [
      ...(additionalComponentGroups ?? []),
    ]
    const armrestsGroup =
      advancedProduct.advanced_product?.additional_component_groups?.find(
        (group) => group.code === 'armrest',
      )

    if (!armrestsGroup) return

    // const armrestsGroup = originalAdditionalComponentGroups[armrestsGroupIndex]

    const initialGroupsString = JSON.stringify(
      additionalComponentGroups
        .map((a) => a.code)
        .sort((a, b) => a.localeCompare(b)),
    )

    let modifiedadditionalComponentGroups =
      originalAdditionalComponentGroups.filter(
        (group) => !group.code.startsWith('armrest'),
      )

    //duplicate armrests group some times as modelWithArmrestInUse count

    modelWithArmrestInUse
      .sort((a, b) => a.sofaModuleId.localeCompare(b.sofaModuleId))
      .forEach((model, i) => {
        const newGroupCode = 'armrest-' + model.code
        const nameOveride =
          'Porankis moduliui ' + model.name + ' ' + model.armrestPosition
        const newArmrestsGroup = {
          ...armrestsGroup,
          code: newGroupCode,
          nameOveride: nameOveride,
          order: armrestsGroup.order + (i + 1) * 0.1,
          additional_components: armrestsGroup.additional_components
            .map((component) => ({
              ...component,
              groupCode: newGroupCode,
              groupNameOveride: nameOveride,
              moduleId: model.sofaModuleId,
            }))
            .filter((component) => {
              if (model.armrestsConditions?.length > 0) {
                return model.armrestsConditions.includes(component.code)
              }
              return true
            }),
        }
        modifiedadditionalComponentGroups.push(newArmrestsGroup)
      })

    const afterModsGroupsString = JSON.stringify(
      modifiedadditionalComponentGroups
        .map((a) => a.code)
        .sort((a, b) => a.localeCompare(b)),
    )

    // if groupps count reduced we need to update selected components
    if (initialGroupsString !== afterModsGroupsString) {
      setAdditionalComponentGroups(modifiedadditionalComponentGroups)

      //after componentd groups are updated we need to update selected components
      const selectedComponents = selectedAdditionalComponents.filter(
        (component) =>
          modifiedadditionalComponentGroups.find(
            (group) => group.code === component.groupCode,
          ),
      )

      selectedAdditionalComponentsVar(selectedComponents)
    }
  }, [selectedSoafForm])

  //=== We need to select the first item of each group
  useEffect(() => {
    // console.log('Selecting component groups')
    if (additionalComponentGroups) {
      let selectedComponents = [] as any
      for (const group of additionalComponentGroups) {
        //--
        let firstComponent = group.additional_components?.[0]
        if (firstComponent) {
          selectedComponents.push(firstComponent)
        }
      }
      selectedAdditionalComponentsVar(selectedComponents)
    }
  }, [advancedProductVarsReseted])

  let fabricCombinations = [
    ...advancedProduct?.advanced_product.fabricCombinations,
  ]

  if (fabricCombinations.length > 1 && fabricCombinations[0].code) {
    fabricCombinations.sort((a, b) => a.code.localeCompare(b.code))
  }

  const { meta_content } = advancedProduct?.advanced_product?.product_details

  console.log('advancedProduct', selectedAdditionalComponents)

  if (advancedProduct?.advanced_product?.advanced_product_type === 'SOFA') {
    return (
      <Grid container justifyContent="space-evenly">
        {meta_content && (
          <Grid container justifyContent="space-evenly">
            <SectionTitle title={t('pricelist-text')} />
            <Grid
              container
              justifyContent="space-evenly"
              spacing={0}
              // marginTop={1}
              marginLeft={3}
              marginRight={1}
            >
              <PriceListTable data={meta_content} />
            </Grid>
          </Grid>
        )}
        <Grid container id="configurator-header">
          <SectionTitle title={sofaConfigurationText} />

          <Grid item xs={12} md={8}>
            {selectedAdvancedProductFabricCombination.fabricCombination?.image
              ?.src_md && (
              <Box
                sx={{
                  height: 300,
                  marginBottom: 2,
                  marginRight: 2,
                  backgroundColor: 'white',
                }}
              >
                <CardMedia
                  component="img"
                  sx={{
                    height: '100%',
                    objectFit: 'contain',
                    width: '100%',
                  }}
                  image={
                    selectedAdvancedProductFabricCombination.fabricCombination
                      ?.image?.src_md
                  }
                />
              </Box>
            )}

            <SofaShapeSelect
              discount={discount}
              sofaForms={sofaForms}
              showHeading={false}
              showModulesSlider={false}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FabricsWithCombinationsSelect
              //ref={refFabric}
              fabricCombinations={fabricCombinations}
              fabricGroups={availablefabricGroupsSorted}
            />
            {showComponents &&
              additionalComponentGroups
                ?.sort((a, b) => a.order - b.order)
                // filter out threads group if there are multiple fabrics. VILMERS  hack
                ?.map((componentGroup, i) => (
                  <AdditionalComponentGroupWrapper
                    key={i}
                    componentGroup={componentGroup}
                    locale={locale}
                  />
                ))}
          </Grid>
        </Grid>

        <Grid item xs={12} lg={12}>
          {/* {showComponents && additionalComponentGroups?.length > 0 && (
            <SectionTitle title={componentsText} />
          )} */}

          <Grid item xs={12} lg={12}>
            {showConfiguration && (
              <SofaConfigurationPreview
                discount={discount}
                advancedProduct={advancedProduct}
              />
            )}
          </Grid>
        </Grid>
        {showAddToCartButton && (
          <StickyPricePreview
            productPermalink={productPermalink}
            advancedProduct={advancedProduct}
            discount={discount}
            steps={[]}
            viewingStep={0}
            finalStep={true}
          />
        )}
      </Grid>
    )
  } else {
    return <span></span>
  }
}
