import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Theme,
  Typography,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useRef, useState } from 'react'
// import { ReactSortable } from 'react-sortablejs' // to delete

import { useReactiveVar } from '@apollo/client'
import {
  advancedProductTotalPriceVar,
  advancedProductTotalPricesPerModuleVar,
  advancedProductVarsResetedVar,
  selectedAdditionalComponentsVar,
  selectedAdvancedProductFabricCombinationVar,
  selectedAdvancedProductFabricVar,
  selectedSofaCombinationsVar,
  sofaScaleVar,
} from '../../../../store/reactiveVars'
import ConfigurationBlock from './Components/ConfigurationBlock'
import {
  getAdvancedProductPrice,
  getPriceFromPriceCategories,
} from '../../../../utilityFunctions/priceUtils'
import SectionTitle from './SectionTitle'
import { useAppContext } from '../../../../context/appContext'
import { getComponentGroupName } from '../../../../utilityFunctions/getComponentGroupName'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      card: {
        position: 'relative',
      },

      sectionTitle: {
        marginTop: '50px',
        marginLeft: '30px',
        marginRight: '30px',
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
        [theme.breakpoints.down('lg')]: {
          marginTop: '10px',
        },
      },

      mainBox: {
        padding: '10px',
      },
    }),
  { name: 'MuiChairConfigurationPreview' },
)

export default function AdvancedProductConfigurationPreview({
  advancedProduct,
  discount,
  ...props
}) {
  const classes = useStyles() as any
  const { locale } = useRouter()

  const { t, i18n } = useTranslation(['common'])
  const reviewYourConfigurationText = t('review-your-configuration-text')
  const pleaseSelectConfigurationText = t('please-select-a-configuration-text')
  const { priceListId } = useAppContext()

  // ------ STATE -----
  // const [clickeSofaShapes, setClickeSofaShapes] = useState([])

  const selectedAdvancedProductFabric = useReactiveVar(
    selectedAdvancedProductFabricVar,
  )

  const selectedAdditionalComponents = useReactiveVar(
    selectedAdditionalComponentsVar,
  )

  //====== END PRICE CALCULATTIONS  (above) ======

  const advancedProductVarsReseted = useReactiveVar(
    advancedProductVarsResetedVar,
  )

  const additionalComponentGroups =
    advancedProduct.advanced_product.additional_component_groups
  const selectedAdditionalComponentsWithGroupNames =
    selectedAdditionalComponents.map((component) => ({
      ...component,
      groupName: getComponentGroupName(
        additionalComponentGroups,
        component.additionalComponentGroupId,
        locale,
      ),
    }))
  // Data
  // 1) We have a product container (For discounts)
  // 2) We have a selected fabric
  // 3) we have sofa combinations

  return (
    <Grid container justifyContent="space-evenly" spacing={1}>
      <SectionTitle title={reviewYourConfigurationText} />

      <Grid container justifyContent="space-evenly" spacing={0} marginTop={3}>
        <Grid item md={12} style={{ width: '100%' }}>
          {selectedAdvancedProductFabric.fabricGroupObject ? (
            <ConfigurationBlock
              discount={discount}
              productName={advancedProduct.name}
              selectedFabric={selectedAdvancedProductFabric}
              selectedAddtionalComponents={
                selectedAdditionalComponentsWithGroupNames
              }
              advancedProduct={advancedProduct}
              fabricDisabled={false}
            />
          ) : (
            <>
              {selectedAdditionalComponentsWithGroupNames ? (
                <ConfigurationBlock
                  discount={discount}
                  productName={advancedProduct.name}
                  selectedFabric={selectedAdvancedProductFabric}
                  selectedAddtionalComponents={
                    selectedAdditionalComponentsWithGroupNames
                  }
                  advancedProduct={advancedProduct}
                  fabricDisabled={true}
                />
              ) : (
                <p>{pleaseSelectConfigurationText}</p>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
