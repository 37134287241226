import { useReactiveVar } from '@apollo/client'
import { Drawer, Grid, Theme, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'next-i18next'
import React, { useEffect, useState } from 'react'
import {
  componentsDrawerStateVar,
  selectedAdditionalComponentsVar,
  selectedAdvancedProductFabricVar,
  selectedSofaCombinationsVar,
} from '../../../../../store/reactiveVars'
import { useAppContext } from '../../../../../context/appContext'
import { getProfileFromLanguage } from '../../../../../utilityFunctions/getProfile'
import AdditionalComponentDrawerCard from './AdditionalComponentDrawerCard'
import { getValidAdditionalComponents } from './additionalComponentUtils'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      drawer: {
        width: 700,
        padding: theme.spacing(3, 5),
        [theme.breakpoints.only('xs')]: {
          width: '100%',
        },
        [theme.breakpoints.between('sm', 'lg')]: {
          width: '50%',
        },
      },
      topDrawerDescription: {
        margin: theme.spacing(0, 0, 3, 0),
      },
      topDrawerContent: {
        margin: theme.spacing(2, 0, 0, 0),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
        marginBottom: theme.spacing(2),
      },
      closeIcon: {
        cursor: 'pointer',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          opacity: '0.6',
        },
      },
    }),
  { name: 'MuiColorsDrawerComponent' },
)

export default function AdditionalComponentsDrawer() {
  const { t } = useTranslation(['common'])
  const classes = useStyles() as any
  const componentsDrawerState = useReactiveVar(componentsDrawerStateVar)
  const { locale } = useAppContext()

  // find profile  by locale
  const groupProfile = getProfileFromLanguage(
    componentsDrawerState.componentGroup?.additional_component_group_profiles,
    locale,
  )

  const selectedAdvancedProductFabric = useReactiveVar(
    selectedAdvancedProductFabricVar,
  )

  const selectedSofaCombinations = useReactiveVar(selectedSofaCombinationsVar)

  const selectedComponents = useReactiveVar(selectedAdditionalComponentsVar)
  //find component for current group
  // chek  by group code or groupp id.
  let selectedComponent
  if (componentsDrawerState.componentGroup?.code?.length > 0) {
    selectedComponent = selectedComponents.find(
      (component) =>
        component.groupCode === componentsDrawerState.componentGroup?.code,
    )
  } else {
    selectedComponent = selectedComponents.find(
      (component) =>
        component.additionalComponentGroupId ===
        componentsDrawerState.componentGroup?.id,
    )
  }

  const onSelectedComponentChange = (component) => {
    selectedAdditionalComponentsVar([
      ...selectedComponents.filter(
        (component) =>
          component?.groupCode !== componentsDrawerState.componentGroup?.code &&
          component?.additionalComponentGroupId !==
            componentsDrawerState.componentGroup?.id,
      ),
      component,
    ])
  }

  const [filteredComponents, setFilteredComponents] = useState([])
  useEffect(() => {
    const additionalComponents = getValidAdditionalComponents(
      componentsDrawerState.componentGroup,
      selectedComponents,
      selectedSofaCombinations,
    )

    setFilteredComponents(additionalComponents)
  }, [
    selectedComponents,
    componentsDrawerState.componentGroup,
    selectedSofaCombinations,
  ])

  const sortedComponents = filteredComponents?.[0]?.code
    ? filteredComponents.sort((a, b) => a.code.localeCompare(b.code))
    : filteredComponents

  return (
    <>
      <Drawer
        classes={{
          paper: classes.drawer,
        }}
        anchor="right"
        open={componentsDrawerState.open}
        transitionDuration={{ enter: 600, exit: 200 }}
        onClose={() =>
          componentsDrawerStateVar({
            open: false,
            data: null,
            componentGroup: null,
          })
        }
      >
        <div className={classes.topDrawerContent}>
          <Typography variant="h5">{groupProfile?.name}</Typography>
          <CloseIcon
            className={classes.closeIcon}
            onClick={() =>
              componentsDrawerStateVar({
                open: false,
                data: null,
                componentGroup: null,
              })
            }
          />
        </div>
        <div className={classes.topDrawerDescription}>
          {' '}
          <Typography variant="caption">{groupProfile?.description}</Typography>
        </div>

        <Grid container spacing={2} style={{ padding: 1 }}>
          {sortedComponents?.map((component, index) => {
            return (
              <Grid item xs={6} sm={6} md={4} key={index}>
                <AdditionalComponentDrawerCard
                  // This is some syntax bug but original id is passed as ID, however it should be product container ID from the top
                  additionalComponent={component}
                  selected={selectedComponent?.id === component.id}
                  onClick={onSelectedComponentChange}
                  selectedAdvancedProductFabric={selectedAdvancedProductFabric}
                />
              </Grid>
            )
          })}
        </Grid>
      </Drawer>
    </>
  )
}
