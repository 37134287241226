import { initializeApollo } from '../../../lib/apolloClient'
import { unset } from '../../utilityFunctions/dataUtils/unset'
import {
  GET_ADDITIONAL_ADVANCED_PRODUCT_DATA,
  GET_ADDITIONAL_COMPONENTS,
  GET_PRODUCT_IN_PRODUCT_PAGE,
  GET_PRODUCT_IN_PRODUCT_PAGE_NO_COMPONENTS,
  GET_PRODUCT_IN_PRODUCT_PAGE_SHORT,
} from '../queries'

export const getProductDataForProductPage = async (
  productPermalink,
  priceListId = 1,
  palettes = [],
  useCache = true,
) => {
  const usingDynamicData = process.env.NEXT_DYNAMIC_PRODUCT_PAGE

  const apolloClient = initializeApollo()
  let productData = await apolloClient.query({
    query: usingDynamicData
      ? GET_PRODUCT_IN_PRODUCT_PAGE_SHORT
      : GET_PRODUCT_IN_PRODUCT_PAGE,
    variables: {
      productPermalink: productPermalink,
      priceListId: priceListId,
    },
    fetchPolicy: useCache ? undefined : 'network-only',
  })

  let productDataModified = { ...productData }
  Object.assign(productDataModified, JSON.parse(JSON.stringify(productData)))

  let additionalProductData
  if (
    !usingDynamicData ??
    productData.data?.findUniqueMetaInformation?.advanced_product_profile
  ) {
    // --- We have an advanced product
    const advancedProductContainerID =
      productData.data?.findUniqueMetaInformation?.advanced_product_profile
        ?.advanced_product?.product_container.id

    const fabricWhere =
      palettes.length < 1
        ? {}
        : {
            fabric_palettes: {
              some: {
                id: { in: palettes },
              },
            },
          }

    additionalProductData = await apolloClient.query({
      query: GET_ADDITIONAL_ADVANCED_PRODUCT_DATA,
      variables: {
        id: advancedProductContainerID,
        priceListId: priceListId,
        fabricWhere,
      },
      fetchPolicy: useCache ? undefined : 'network-only',
    })
  }

  // --- New structure ---
  const data = productData.data.findUniqueMetaInformation
  if (!data) return null

  const containerData =
    data.advanced_product_profile?.advanced_product?.product_container ||
    data.product_profile?.single_product?.product_container
  let productDataFull

  if (data.product_profile?.single_product) {
    productDataFull = {
      ...containerData,
      name: data?.product_profile?.name ?? null,
      content_blocks: [...containerData.content_blocks]?.sort((a, b) =>
        a.arrangement > b.arrangement ? 1 : -1,
      ),
      single_product: {
        ...data.product_profile?.single_product,
        product_profile: {
          ...data.product_profile,
          meta_information: {
            meta_description: data.meta_description,
            meta_title: data.meta_title,
            permalink: data.permalink,
          },
        },
      },
    }
  }

  if (data.advanced_product_profile?.advanced_product) {
    productDataFull = {
      ...containerData,
      name: data?.advanced_product_profile?.name ?? null,
      content_blocks: [...containerData.content_blocks]?.sort((a, b) =>
        a.arrangement > b.arrangement ? 1 : -1,
      ),
      advanced_product: {
        ...data.advanced_product_profile?.advanced_product,
        advanced_product_profile: {
          ...data.advanced_product_profile,
          meta_information: {
            meta_description: data.meta_description,
            meta_title: data.meta_title,
            permalink: data.permalink,
          },
        },
        ...(additionalProductData?.data?.findUniqueProductContainer
          ?.advanced_product ?? {}),
      },
      manufacturer: {
        ...containerData.manufacturer,
        ...(additionalProductData?.data?.findUniqueProductContainer
          .manufacturer ?? {}),
      },
    }
  }
  if (productDataFull.style) {
    productDataFull.style = {
      ...productDataFull.style,
      product_containers: productDataFull.style?.product_containers.slice(
        0,
        10,
      ),
    }
  }
  if (productDataFull.collection) {
    productDataFull.collection = {
      ...productDataFull.collection,
      product_containers: productDataFull.collection?.product_containers.slice(
        0,
        10,
      ),
    }
  }

  unset(productDataFull, 'single_product.product_container')
  unset(productDataFull, 'single_product.product_profile.single_product')
  unset(productDataFull, 'advanced_product.product_container')
  unset(
    productDataFull,
    'advanced_product.advanced_product_profile.advanced_product',
  )

  const allGroups =
    productDataFull.manufacturer.additional_component_groups ?? []
  const componentGroups =
    productDataFull.advanced_product?.additional_component_group_to_advanced_product
      ?.filter((group) => group.enabled)
      .map((group) => {
        const groupData = allGroups.find(
          (g) => g.id == group.additional_component_group.id,
        )
        return {
          ...groupData,
          additional_components:
            productDataFull?.advanced_product?.additional_component_to_advanced_product
              // ?.filter((c) => c.enabled)
              .map((component) => ({
                ...component,
                ...(groupData.additional_components.find(
                  (c) => c.id == component.additional_component.id,
                ) ?? {}),
                use_fabric_prices_for_components:
                  groupData.use_fabric_prices_for_components,
                conditions: component.conditions,
                groupCode: groupData.code ?? '',
              }))
              .filter((c) => c.additional_component_profiles)
              // sort so cmponent that has value isDefault = true is first
              .sort((a, b) => {
                if (a.isDefault && !b.isDefault) {
                  return -1
                }
                if (!a.isDefault && b.isDefault) {
                  return 1
                }
                return 0
              }),
        }
      })
      .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
  if (componentGroups) {
    productDataFull.advanced_product.additional_component_groups =
      componentGroups
  }

  unset(productDataFull, 'manufacturer.additional_component_groups')
  unset(
    productDataFull,
    'advanced_product.additional_component_to_advanced_product',
  )
  unset(
    productDataFull,
    'advanced_product.additional_component_group_to_advanced_product',
  )

  return productDataFull
}

export const getProductDataForProductPage2 = async (
  productPermalink,
  priceListId = 1,
  palettes = [],
  useCache = true,
  language = 'lt',
) => {
  const apolloClient = initializeApollo()
  let productData = await apolloClient.query({
    query: GET_PRODUCT_IN_PRODUCT_PAGE_NO_COMPONENTS,
    variables: {
      productPermalink: productPermalink,
    },
    fetchPolicy: useCache ? undefined : 'network-only',
  })

  let productDataModified = { ...productData }
  Object.assign(productDataModified, JSON.parse(JSON.stringify(productData)))

  let additionalProductData
  let additionalComponentsData

  if (productData.data?.findUniqueMetaInformation?.advanced_product_profile) {
    // --- We have an advanced product
    const advancedProductContainerID =
      productData.data?.findUniqueMetaInformation?.advanced_product_profile
        ?.advanced_product?.product_container.id

    const fabricWhere =
      palettes.length < 1
        ? undefined
        : {
            fabric_palettes: {
              some: {
                id: { in: palettes },
              },
            },
          }

    const [_additionalProductData, _additionalComponentsData] =
      await Promise.all([
        apolloClient.query({
          query: GET_ADDITIONAL_ADVANCED_PRODUCT_DATA,
          variables: {
            id: advancedProductContainerID,
            priceListId: priceListId,
            fabricWhere,
          },
          fetchPolicy: useCache ? undefined : 'network-only',
        }),
        apolloClient.query({
          query: GET_ADDITIONAL_COMPONENTS,
          variables: {
            id: advancedProductContainerID,
            language: language,
          },
          fetchPolicy: useCache ? undefined : 'network-only',
        }),
      ])

    additionalProductData =
      _additionalProductData.data.findUniqueProductContainer
    additionalComponentsData =
      _additionalComponentsData.data.findUniqueProductContainer
    console.log('=======additionalComponentsData', additionalComponentsData)
  }

  // --- New structure ---
  const data = productData.data.findUniqueMetaInformation
  if (!data) return null

  const containerData =
    data.advanced_product_profile?.advanced_product?.product_container ||
    data.product_profile?.single_product?.product_container
  let productDataFull

  if (data.product_profile?.single_product) {
    productDataFull = {
      ...containerData,
      name: data?.product_profile?.name ?? null,
      content_blocks: [...containerData.content_blocks]?.sort((a, b) =>
        a.arrangement > b.arrangement ? 1 : -1,
      ),
      single_product: {
        ...data.product_profile?.single_product,
        product_profile: {
          ...data.product_profile,
          meta_information: {
            meta_description: data.meta_description,
            meta_title: data.meta_title,
            permalink: data.permalink,
          },
        },
      },
    }
  }

  if (data.advanced_product_profile?.advanced_product) {
    productDataFull = {
      ...containerData,
      name: data?.advanced_product_profile?.name ?? null,
      content_blocks: [...containerData.content_blocks]?.sort((a, b) =>
        a.arrangement > b.arrangement ? 1 : -1,
      ),
      advanced_product: {
        ...data.advanced_product_profile?.advanced_product,
        advanced_product_profile: {
          ...data.advanced_product_profile,
          meta_information: {
            meta_description: data.meta_description,
            meta_title: data.meta_title,
            permalink: data.permalink,
          },
        },
        ...additionalProductData.advanced_product,
      },
      manufacturer: {
        ...containerData.manufacturer,
        fabric_price_category:
          additionalProductData.manufacturer.fabric_price_category,
      },
    }
  }

  //
  if (productDataFull.style) {
    productDataFull.style = {
      ...productDataFull.style,
      product_containers: productDataFull.style?.product_containers.slice(
        0,
        10,
      ),
    }
  }
  if (productDataFull.collection) {
    productDataFull.collection = {
      ...productDataFull.collection,
      product_containers: productDataFull.collection?.product_containers.slice(
        0,
        10,
      ),
    }
  }

  unset(productDataFull, 'single_product.product_container')
  unset(productDataFull, 'single_product.product_profile.single_product')
  unset(productDataFull, 'advanced_product.product_container')
  unset(
    productDataFull,
    'advanced_product.advanced_product_profile.advanced_product',
  )

  //console.log("additionalComponentsData", additionalComponentsData)

  const componentsData = additionalComponentsData.advanced_product
  const additionalComponents =
    componentsData.additional_component_to_advanced_product.map((ad) => ({
      ...ad,
      ...ad.additional_component,
      additional_component: undefined,
    }))
  const componentGroups =
    componentsData.additional_component_group_to_advanced_product.map(
      (group) => {
        const groupId = group.additional_component_group.id
        const groupCode = group.additional_component_group.code ?? ''
        return {
          ...group.additional_component_group,
          additional_components: additionalComponents
            .filter(
              (component) => groupId === component.additionalComponentGroupId,
            )
            .map((component) => ({
              ...component,
              groupCode,
              use_fabric_prices_for_components:
                group.additional_component_group
                  .use_fabric_prices_for_components,
            }))
            .sort((a, b) => {
              // HACK for vilmers, to avoind 000 armrest to be first, cause its widts is 0
              if (group.additional_component_group.code === 'armrest') {
                const code = a.code.split(':')[1]
                if (code === 'A000') {
                  return 1
                }
                return -1
              }

              if (a.isDefault && !b.isDefault) {
                return -1
              }
              if (!a.isDefault && b.isDefault) {
                return 1
              }
              return 0
            }),
        }
      },
    )

  //       }
  //     }).sort((a, b) =>  a.createdAt.localeCompare(b.createdAt))
  if (componentGroups) {
    productDataFull.advanced_product.additional_component_groups =
      componentGroups
  }

  // unset(productDataFull, 'manufacturer.additional_component_groups')
  // unset(
  //   productDataFull,
  //   'advanced_product.additional_component_to_advanced_product',
  // )
  // unset(
  //   productDataFull,
  //   'advanced_product.additional_component_group_to_advanced_product',
  // )

  return productDataFull
}
