import { Grid, Paper, Theme, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import 'slick-carousel/slick/slick-theme.css'
// Import css files
import 'slick-carousel/slick/slick.css'
import 'swiper/css/bundle'

import {
  fabricsDrawerStateVar,
  selectedAdvancedProductFabricVar,
} from '../../../../../store/reactiveVars'
import { useTranslation } from 'react-i18next'
import { useAppContext } from '../../../../../context/appContext'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useReactiveVar } from '@apollo/client/react/hooks/useReactiveVar'
import { getProfileFromLanguage } from '../../../../../utilityFunctions/getProfile'
import { useStyles } from './groupWithDrawerStyles'

const FabricsSelectWithDrawer = ({
  fabricGroups,
  title = null,
  option,
}): JSX.Element => {
  const classes = useStyles() as any
  //=== Crunch data here...
  const { locale } = useAppContext()

  const selectedAdvancedProductFabric = useReactiveVar(
    selectedAdvancedProductFabricVar,
  )

  // Setting default fabric if not set, when used in combination
  if (
    option?.id &&
    !selectedAdvancedProductFabric.combinationFabrics?.[option.id] &&
    fabricGroups[0]?.fabrics[0]
  ) {
    selectedAdvancedProductFabricVar({
      ...selectedAdvancedProductFabric,
      fabricGroupObject: fabricGroups[0],
      fabricObject: fabricGroups[0].fabrics[0],
      combinationFabrics: option
        ? {
            ...selectedAdvancedProductFabricVar().combinationFabrics,
            [option.id]: {
              fabricGroupObject: fabricGroups[0],
              fabricObject: fabricGroups[0].fabrics[0],
              option: option,
            },
          }
        : null,
    })
  } else if (
    // Setting default fabric if not set, when used in single
    !option?.id &&
    !selectedAdvancedProductFabric.fabricObject &&
    fabricGroups[0]?.fabrics[0]
  ) {
    selectedAdvancedProductFabricVar({
      ...selectedAdvancedProductFabric,
      fabricGroupObject: fabricGroups[0],
      fabricObject: fabricGroups[0].fabrics[0],
    })
  }

  const { t, i18n } = useTranslation(['common'])
  const fabricText = title ?? t('fabric')
  const fabricGroupText = t('fabric-group')
  const fabricCodeText = t('fabric-code')

  console.log('option', option)

  let selectedGroup
  let selectedFabric
  if (option) {
    selectedGroup =
      selectedAdvancedProductFabric?.combinationFabrics?.[option.id]
        ?.fabricGroupObject
    //   : selectedAdvancedProductFabric.fabricGroupObject
    selectedFabric =
      selectedAdvancedProductFabric?.combinationFabrics?.[option.id]
        ?.fabricObject
    //   : selectedAdvancedProductFabric.fabricObject
  } else {
    selectedGroup = selectedAdvancedProductFabric.fabricGroupObject
    selectedFabric = selectedAdvancedProductFabric.fabricObject
  }

  console.log('selectedGroup', selectedAdvancedProductFabric, selectedFabric)

  const groupProfile = getProfileFromLanguage(
    selectedGroup?.fabric_group_profiles,
    locale,
  )

  return (
    <Grid id="fabricsSelect" item xs={12} lg={12}>
      <section className={classes.section}>
        <div
          className={classes.row}
          onClick={() => {
            fabricsDrawerStateVar({
              open: true,
              data: fabricGroups,
              option,
            })
          }}
        >
          <div className={classes.rowContentContainer}>
            <div className={classes.imageContainer}>
              {selectedFabric?.image?.src_thumbnail && (
                <img
                  src={selectedFabric?.image?.src_thumbnail}
                  loading="lazy"
                  className={classes.componentImage}
                />
              )}
            </div>
            <div>
              <Typography
                component="p"
                variant="caption"
                display="block"
                style={{ fontSize: '1.2em' }}
              >
                {fabricText}
              </Typography>
              <Typography component="p" variant="body2" display="block">
                {`${fabricGroupText} ${
                  groupProfile?.name
                } | ${fabricCodeText} ${
                  selectedFabric?.color_name ?? selectedFabric?.code
                }`}
              </Typography>
            </div>
          </div>
          <ChevronRightIcon className={classes.chevronRight} />
        </div>
      </section>
    </Grid>
  )
}

export default FabricsSelectWithDrawer
