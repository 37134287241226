//* product_type enum
export enum ProductType {
  SIMPLE_PRODUCT = 'SIMPLE_PRODUCT',
  ADVANCED_PRODUCT = 'ADVANCED_PRODUCT',
  ADVANCED_PRODUCT_VARIANT = 'ADVANCED_PRODUCT_VARIANT',
}

//* language enum
export enum Language {
  lt = 'lt',
  lv = 'lv',
  et = 'et',
  en = 'en',
  ru = 'ru',
}

//* order status enum
export enum OrderStatus {
  CANCELLED = 'CANCELLED',
  AWAITING_PAYMENT = 'AWAITING_PAYMENT',
  PAYMENT_COMPLETED = 'PAYMENT_COMPLETED',
  REFUNDED = 'REFUNDED',
  PARTIALY_DELIVERED = 'PARTIALY_DELIVERED',
  COMPLETED = 'COMPLETED',
  AWAITING_SINCHRONIZATION = 'AWAITING_SINCHRONIZATION',
  AWAITING_CONFIRMATION = 'AWAITING_CONFIRMATION',
  ERROR = 'ERROR',
}

//* order ITEM status enum
export enum OrderItemStatus {
  CANCELLED = 'CANCELLED',
  AWAITING_REFUND = 'AWAITING_REFUND',
  AWAITING_PAYMENT = 'AWAITING_PAYMENT',
  PENDING = 'PENDING',
  MANUFACTURING = 'MANUFACTURING',
  AWAITING_PICKUP = 'AWAITING_PICKUP',
  IN_TRANSIT = 'IN_TRANSIT',
  DELIVERED = 'DELIVERED',
}

//* ==========================
//* ======= CART ITEM ========
//* ==========================
export interface CartItem {
  id: number
  name: string
  price: number
  sku: string | null
  discountedPrice: number
  discounted: boolean
  image: string
  quantity: number | null
  minimum_order_quantity: number | null
  permalink: string
  product_type: ProductType
  dispatchInformation: object
  advanced_product_data: object | null
  product_container_id: number
  integration_configuration: object[]
}

//* ==========================
//* ===== WISHLIST ITEM ======
//* ==========================
export interface WishlistItem {
  id: number
  name: string
  price: number
  sku: string
  discountedPrice: number
  discounted: boolean
  image: string
  permalink: string
  product_type: ProductType
  dispatchInformation: object
}

//* ==========================
//* ==== RECENTLY VIEWED =====
//* ==========================
export interface RecentlyViewedItem {
  id: number
  name: string
  price: number
  discountedPrice: number
  discounted: boolean
  image: string
  permalink: string
  product_type: ProductType
  dispatchInformation: object
}

//* ================================
//* ==== CHECKOUT ADDRESS FORM =====
//* ================================
export interface AddressFormData {
  address1: string
  address2?: string
  city: string
  country: string
  state?: string
  zip: string
}

//* ================================
//* =========== OrderItem ==========
//* ================================
export interface OrderItem {
  id: number
  product_container: {
    id: number
  }
  price: number
  shipping_price: number
  quantity: number
  expected_delivery_date: string
  preferred_delivery_date: string | null
  sku: string
  shipping_method: {
    id: number
  }
  status: OrderItemStatus
  product_type: string
  advanced_product_data: object | null
  integration_configuration: object[]
}

//* ================================
//* ======= ORDER INTERFACE ========
//* ================================
export interface Order {
  address1: string
  billing_address1: string
  address2: string
  billing_address2: string
  city: string
  billing_city: string
  country: string
  billing_country: string
  email: string
  firstName: string
  lastName: string
  order_items: OrderItem[]
  phone: string
  promo: boolean
  state?: string
  billing_state?: string
  postal_code: string
  billing_postal_code: string
  discount_applied: number
  total_shipping_price: number
  sub_total_price: number
  total_price_without_VAT: number
  total_price: number
  buying_as_company: boolean
  company_name: string
  company_code: string
  vat_code: string
  zero_vat: boolean
  is_lithuanian_company: boolean
  coupon: Record<string, any>
  metadata: Record<string, any>
}
